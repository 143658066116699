.bottom-nav {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 99;
}

.bottom-nav-bg {
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -5px 5px rgba(12,9,42,0.1);
    /* width: 456px;*/
    height: 70px;  
    background: rgba(33, 37, 41, 1);
    box-shadow: 0px -1px 12px 0px rgba(0, 0, 0, 0.1); 

}
.bottom-nav .col {
    min-width: 25%;
}
.bottom-nav .nav-box {
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: normal;
    display: block;
    text-decoration: none;
    position: relative;
}
.bottom-nav .active .nav-box a {
    color: #FE9E44;
}
.bottom-nav .nav-box-ico {
    font-size: 1.5rem;
    color: #969696;
    display: block;
    text-decoration: none;
}

.bottom-nav .nav-box-ico img{
    width: 98px;
    height: 50px; 
    border-radius: 100px;
    gap: 20px;
    
}