.modal-level-title {
    text-align: center;
    font-size: 1.125rem;
    margin-bottom: 24px;
    font-weight: bold;
}
.modal-level-option .btn:nth-child(1) {
    background-color: #fec590;
}
.modal-level-option .btn {
    margin-bottom: 1rem;
}
.button-primary {
    background-color: #FE9E44;
    color: #fff;
    font-weight: bold;
}
.btn {
    border: 8px;
}
.difficult {
    /* padding-top: 3rem; */
}
.difficult .home-profile {
    background-color: #fff;
    padding: 1rem;
    border-radius: 10px;
    margin-bottom: 24px;
}
.difficult .home-profile-name {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left; 
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 95%;
    color: rgba(33, 37, 41, 1); 
}
.choose-title{
    font-size: 1rem;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(108, 117, 125, 1);

}
.beginner-tab{ 
    height: 74px;
    padding: 10px;
    border-radius: 10px; 
    background: rgba(254, 158, 68, 0.1);  
    cursor: pointer;
}
.intermediate-tab{ 
    height: 74px;
    padding: 10px;
    border-radius: 10px; 
    background: rgba(233, 247, 230, 1);
    cursor: pointer;
}
.advance-tab{ 
    height: 74px;
    padding: 10px;
    border-radius: 10px; 
    background: rgba(242, 84, 91, 0.1);
    cursor: pointer;
}
.beginner-tab .avatar2 img {
    object-fit: contain;
    object-position: center center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
}
.intermediate-tab .avatar2 img {
    object-fit: contain;
    object-position: center center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
}
.advance-tab .avatar2 img {
    object-fit: contain;
    object-position: center center;
    width: 54px;
    height: 54px;
    border-radius: 50%;
}

.beginner-tab .home-profile-points {
    color: linear-gradient(0deg, #FE9E44, #FE9E44),
    linear-gradient(0deg, #FFFFFF, #FFFFFF); 
    border-radius: 100px; 
    padding: 6px 9px;
    display: inline-block;
    font-size: 0.875rem;
    vertical-align: middle;
    border: 1px solid rgba(254, 158, 68, 1);
    display: flex;
    align-items: center;
    gap: 6px;
}
.beginner-tab .points-text{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(254, 158, 68, 1);

} 
.intermediate-tab .home-profile-points {
    color: linear-gradient(0deg, #47C475, #47C475),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
    
    border-radius: 100px; 
    padding: 6px 9px;
    display: inline-block;
    font-size: 0.875rem;
    vertical-align: middle;
    border: 1px solid rgba(71, 196, 117, 1);
    display: flex;
    align-items: center;
    gap: 6px;
}
.intermediate-tab .points-text{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(71, 196, 117, 1); 
} 
.advance-tab .home-profile-points {
    color: linear-gradient(0deg, #F2545B, #F2545B),
linear-gradient(0deg, #FFFFFF, #FFFFFF);

    border-radius: 100px; 
    padding: 6px 9px;
    display: inline-block;
    font-size: 0.875rem;
    vertical-align: middle;
    border: 1px solid rgba(242, 84, 91, 1);
    display: flex;
    align-items: center;
    gap: 6px;
}
.advance-tab .points-text{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(242, 84, 91, 1);


} 
.arrow{
    width: 32px;
    height: 32px;
    top: 43.96px;
    left: 727.87px; 
}
.levels-title{
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1); 
    padding-top: 4px; 
}

.page-header-back {
    color: #fff;
    display: inline-block;
    position: absolute;
    margin-right: 0.5rem;
    left: -0.25rem;
}
.page-header .game-title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em; 
    color: rgba(255, 255, 255, 1);  
}

.quiz-thumbnail {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-bottom: 32px;
    border-radius: 8px;
}
.quiz-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 8px;
}

.quiz-info {
    background-color: #fff;
    padding: 24px 1rem;
    border-radius: 10px;
    margin-bottom: 24px;
}
.quiz-info-meta { 
    padding: 6px 10px 6px 10px;
    border-radius: 100px;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    height: 53px;
    width: 154px;
    gap: 8px;
    background: rgba(254, 158, 68, 0.1);

}
.quiz-info-meta2 { 
    padding: 6px 10px 6px 10px;
    border-radius: 100px;
    font-size: 0.875rem;
    margin-bottom: 1rem;
    height: 53px;
    width: 154px;
    gap: 8px;
    background: rgba(71, 196, 117, 0.1); 
}
.quiz-info-meta-circle-img {
    width: 41px;
    height: 41px; 
}
.quiz-info-content {
    color: #969696;
    margin-bottom: 2rem;
}
.quiz-info-content-title {
    font-weight: bold;
    font-size: 1rem;
    color: #0C092A;
    margin-bottom: 0.875rem;
}
.quiz-info-content p:last-child {
    margin-bottom: 0;
    font-family: Roboto;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;

}
.quiz-info .button-primary {
    background: rgba(40, 113, 251, 1); 
    color: #fff; 
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    height: 49px;
    border-radius: 10px;
}
.quiz-info .button-primary:hover {
    background: rgba(40, 113, 251, 1); 
    color: #fff;
}
.section-title2 {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(0, 0, 0, 1);
}

.quiz-info-row {
    gap: 20px;
    align-items: center;
}
.title-gap {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left; 
    padding-left: 8px;
}

.card-simple {
    background-color: #fff;
    padding: 24px 1rem;
    border-radius: 10px;
    margin-bottom: 24px;
}
.opponent {
    text-align: center;
    font-weight: bold;
}
.opponent-avatar {
    width: 80px;
    height: 80px;
    display: inline-block;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0.25rem;
    background-color: #DFDFDF;
}
.opponent-avatar img {
    object-fit: contain;
    object-position: center center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.opponent2 {
    text-align: center;
    font-weight: bold;
    padding-top: 40px;
}
.opponent-avatar2 {
    width: 28px;
    height: 25px;
    display: inline-block;
}
.opponent-avatar2 img {
    object-fit: contain;
    object-position: center center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.opponent-circle {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    background-color: #FFEBD9;
    color: #FE9E44;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.125rem;
}
.opponent-avatar-loading {
    max-width: 75%;
    margin-right: auto;
    margin-left: auto;
    height: 100%;
}

.card-simple .button-primary {
    background: rgba(40, 113, 251, 1); 
    color: #fff; 
    font-family: Roboto;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    height: 49px;
    border-radius: 10px;
}
.card-simple .button-primary:hover {
    background: rgba(40, 113, 251, 1); 
    color: #fff;
}
.card-simple .opponentMsg{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    
}
.name-title{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;

}
.card-row{
    padding: 0 50px 20px 50px;
    border-bottom: 2px dotted rgba(225, 225, 225, 1);
}
@media (max-width: 500px) {
    .card-row{
        padding: 0 20px 20px 15px; 
    }
}