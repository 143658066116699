.login .login-thumbnail {
    width: 195px;
    height: 46px;
    margin-left: auto;
    margin-right: auto;
    text-align: center; 
    border-radius: 8px;
}
.login .login-thumbnail img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
    border-radius: 8px;
}
.login .login-info {
    background-color: #fff;
    padding: 24px 1rem;
    border-radius: 10px;
    margin-bottom: 24px;
}
.login-header{
    padding-top: 3rem;
    padding-bottom: 5rem;
    color: #fff;
    font-weight: bold;
}
.login .modal-level-title { 
    margin-bottom: 24px; 
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;

}

.login .login-thumbnail2 {
    width: 107px;
    height: 75px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;  
}
.login .login-thumbnail2 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center; 
}

.login-title{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(33, 37, 41, 1);
}
.react-custom-flag-select__select__wrapper.react-custom-flag-select__disabled div {
    cursor: auto !important;
}

.pack-main-container {
  display: flex;
  flex-direction: column;
}
.pack-container {
    display: flex;
    flex-direction: column;
  }

  .pack-title{
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(33, 37, 41, 1);
    margin-top: 2%;
    margin-bottom: 2%;
}
  
  .pack {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10px;
    padding: 10px;
    width: 95%;
    display: flex;
    flex-direction: column;
  }
  .pack_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .pack-header {
    padding: 5px;
    font-size: 14px;
    color: #999999;
  }
  
  .pack input[type="radio"] {
    margin-right: 5px;
  }
  
  .pack-details {
    padding: 5px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .pack-btn {
    margin-top: 10px;
    margin-left: 3%;
    width: 50%;
    border-radius: 5px;
  }
  