.bottom-nav {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 99;
}
.bottom-nav-bg {
    background: rgba(33, 37, 41, 1);
    border-radius: 10px 12px 0 0;
    box-shadow: 0 -5px 5px rgba(12,9,42,0.1);
}
.bottom-nav .nav-box {
    text-align: center;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    line-height: normal;
    display: block;
    text-decoration: none;
    position: relative;
}
.bottom-nav .nav-box-ico {
    font-size: 1.5rem;
    color: rgba(150, 150, 150, 1);
    display: block;
    text-decoration: none;
}
.background-primary {
    background: rgba(254, 158, 68, 1);
    color: #fff;
}
.bottom-nav .nav-box.background-primary .nav-box-ico {
    color: #fff;
}
.bottom-nav .nav-box-ico img {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    gap: 20px;
}
.login-msg{
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(108, 117, 125, 1);
    
}
.close-btn{
    border: 1px solid rgba(225, 225, 225, 1);
    width: 73px;
    height: 39px;
    border-radius: 10px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(108, 117, 125, 1); 
}
.close-btn:hover{
    border: 1px solid rgba(225, 225, 225, 1);
}
.btn-primary-login{
    background: rgba(40, 113, 251, 1); 
    width: 73px;
    height: 39px;
    border-radius: 10px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: rgba(255, 255, 255, 1);

}
.btn-primary-login:hover{
    background: rgba(40, 113, 251, 1); 
    color: rgba(255, 255, 255, 1);
}